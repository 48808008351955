import { createComponent } from "../private/editor/base";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Code from "@tiptap/extension-code";
import Paragraph from "@tiptap/extension-paragraph";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import CodeBlock from "@tiptap/extension-code-block";
import Dropcursor from "@tiptap/extension-dropcursor";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";

export default createComponent({
  name: "FullEditor",
  history: true,
  extensions: [
    Dropcursor,
    Image,
    Table.configure({
      resizable: true,
    }),
    TableCell,
    TableHeader,
    TableRow,
  ],
  marks: [
    {
      group: 1,
      ext: Bold,
      title: "Bold",
      shortcut: "Ctrl+B",
      active: (editor) => editor.isActive("bold"),
      handle: (editor) => editor.chain().focus().toggleBold().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleBold().run(),
      icon: "format_bold",
    },
    {
      group: 1,
      ext: Italic,
      title: "Italic",
      shortcut: "Ctrl+I",
      active: (editor) => editor.isActive("italic"),
      handle: (editor) => editor.chain().focus().toggleItalic().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleItalic().run(),
      icon: "format_italic",
    },
    {
      group: 1,
      ext: Strike,
      title: "Strike",
      shortcut: "Ctrl+S",
      active: (editor) => editor.isActive("strike"),
      handle: (editor) => editor.chain().focus().toggleStrike().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleStrike().run(),
      icon: "strikethrough_s",
    },
    {
      group: 1,
      ext: Underline,
      title: "Underline",
      shortcut: "Ctrl+U",
      active: (editor) => editor.isActive("underline"),
      handle: (editor) => editor.chain().focus().toggleUnderline().run(),
      disabled: (editor) =>
        !editor.can().chain().focus().toggleUnderline().run(),
      icon: "format_underlined",
    },
    {
      group: 1,
      ext: Code,
      title: "Code",
      active: (editor) => editor.isActive("code"),
      handle: (editor) => editor.chain().focus().toggleCode().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleCode().run(),
      icon: "code",
    },
    {
      group: 2,
      ext: Heading,
      title: "Level 1 header",
      active: (editor) => editor.isActive("heading", { level: 1 }),
      handle: (editor) =>
        editor.chain().focus().toggleHeading({ level: 1 }).run(),
      textIcon: "H1",
    },
    {
      group: 2,
      title: "Level 2 header",
      active: (editor) => editor.isActive("heading", { level: 2 }),
      handle: (editor) =>
        editor.chain().focus().toggleHeading({ level: 2 }).run(),
      textIcon: "H2",
    },
    {
      group: 2,
      title: "Level 3 header",
      active: (editor) => editor.isActive("heading", { level: 3 }),
      handle: (editor) =>
        editor.chain().focus().toggleHeading({ level: 3 }).run(),
      textIcon: "H3",
    },
    {
      group: 2,
      ext: Link.configure({
        openOnClick: false,
      }),
      title: "Link",
      active: (editor) => editor.isActive("link"),
      handle: (editor) => {
        if (editor.isActive("link")) {
          editor.chain().focus().unsetLink().run();
        } else {
          const previousUrl = editor.getAttributes("link").href;
          const url = window.prompt("URL", previousUrl);

          // cancelled
          if (url === null) {
            return;
          }

          // empty
          if (url === "") {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();

            return;
          }

          // update link
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
        }
      },
      icon: "link",
    },
    {
      group: 2,
      ext: Paragraph,
      title: "Paragraph",
      active: (editor) => editor.isActive("paragraph"),
      handle: (editor) => editor.chain().focus().toggleCode().run(),
      icon: "format_textdirection_r_to_l",
    },
    {
      group: 2,
      ext: BulletList,
      title: "Bullet list",
      active: (editor) => editor.isActive("bulletList"),
      handle: (editor) => editor.chain().focus().toggleBulletList().run(),
      icon: "format_list_bulleted",
    },
    {
      group: 2,
      ext: OrderedList,
      title: "Ordered list",
      active: (editor) => editor.isActive("orderedList"),
      handle: (editor) => editor.chain().focus().toggleOrderedList().run(),
      icon: "format_list_numbered",
    },
    {
      group: 2,
      ext: CodeBlock,
      title: "Code block",
      active: (editor) => editor.isActive("codeBlock"),
      handle: (editor) => editor.chain().focus().toggleCodeBlock().run(),
      icon: "data_object",
    },
    {
      group: 3,
      ext: HorizontalRule,
      title: "Horizontal rule",
      handle: (editor) => editor.chain().focus().setHorizontalRule().run(),
      icon: "horizontal_rule",
    },
    {
      group: 3,
      ext: Blockquote,
      title: "Blockquote",
      active: (editor) => editor.isActive("blockquote"),
      handle: (editor) => editor.chain().focus().toggleBlockquote().run(),
      icon: "format_quote",
    },
    // {
    //   group: 3,
    //   ext: HardBreak,
    //   title: 'Hard break',
    //   handle: (editor) => editor.chain().focus().setHardBreak().run(),
    //   icon: 'format_quote',
    // },
    // {
    //   group: 3,
    //   title: 'Clear marks',
    //   handle: (editor) => editor.chain().focus().unsetAllMarks().run(),
    //   icon: 'clear_all',
    // },
  ],
});
